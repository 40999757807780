.flowtys_woodman {
  width: 290px !important;
  height: 201px !important;
  margin-top: 39px !important;
}

.flowtys_logo {
  width: 180px !important;
  height: 50.3px !important;
  margin-top: 30px !important;
}

.div-text {
  width: 270px !important;
  height: 96px !important;
  border-radius: 10px;
  background-color: rgba(136, 136, 136, 0.5);
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}

.div-text-b {
  height: 120px !important;
}

.div-text-m {
  height: 185px !important;
}

.text-row {
  align-self: center;
}

.main-button:hover {
  background-color: #a89ab0;
}
