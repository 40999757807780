/* @import url(//db.onlinewebfonts.com/c/5ab304ade0d4f62d027d12464ca69443?family=Apple+Color+Emoji); */

body {
  background-color: black;
  background-image: url("./assets/studio.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* color: white; */
}

body {
  height: 100vh;
}

#root {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100%;
}

.viewport-header {
  position: relative;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "SeasideResortNF";
  src: local("SeasideResortNF"),
    url(./assets/SeasideResortNF/SEASRN__.ttf) format("truetype");
}

@font-face {
  font-family: "Lucian";
  src: local("Lucian"), url(./assets/lucien/Lucian.ttf) format("truetype");
}

@font-face {
  font-family: "LucianCAT";
  src: local("Lucian"),
    url(./assets/lucien-schoenschriftv-cat/LucianCAT.ttf) format("truetype");
}

@font-face {
  font-family: "Futura";
  src: local("Futura"), url(./assets/futura/futur.ttf) format("truetype");
}

.navbar-expand {
  margin-left: 97px;
  margin-right: 97px;
  border-bottom: solid 1px #888;
}

@media only screen and (max-height: 900px) {
  .navbar-expand {
    margin-left: 8px;
    margin-right: 8px;
    flex-wrap: wrap !important;
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #888;
  border-radius: 48px;
  background-color: #222;
  border: none;
  opacity: 1 !important;
}

.btn-primary {
  outline: none !important;
  border: none !important;
}

.btn-primary:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  border: none !important;
}

a:hover {
  color: #e5c100;
}

.external-link:hover {
  color: #e5c100;
}

.container {
  max-width: 100%;
}

.main-box {
  height: 45vh !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 13px 40px 15px;
  border-radius: 30px;
  background-color: #fff;
  background-color: #fff;
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.main-connect-button {
  width: 270px;
  height: 90px;
  border-radius: 48px;
  background-color: #fff;
  font-family: Futura;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.main-button:hover {
  background-color: #a89ab0;
}

.studio-title {
  font-family: SeasideResortNF;
  font-size: 20px;
  margin-top: 12px !important;
  margin-left: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.connect-top {
  width: 171px;
  height: 54px;
  border-radius: 30px;
  background-color: #fff;
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.connect-top:focus:not(:focus-visible) {
  outline: none !important;
  border: none !important;
}

.connect-top:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

.external-link {
  width: 16px;
  height: 16px;
  padding: 1px;
  object-fit: contain;
  color: #a89ab0;
  margin-bottom: 3px !important;
}

.balance-top {
  width: 253px;
  margin-top: 15px !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: white;
  margin-right: 10px !important;
}

.connect-top:disabled,
.connect-top.disabled {
  width: 171px;
  height: 54px;
  border-radius: 30px;
  background-color: #222;
  border: none;
}

.connect-top:hover {
  background-color: #a89ab0;
}

.flowty-alert {
  border-radius: 10px !important;
  border: solid 3px rgba(136, 136, 136, 0.5) !important;
  background-color: #222 !important;
  color: white;
  padding-right: 1rem !important;
}

.alert-link-flowty {
  color: #a89ab0;
  text-decoration: none !important;
}

.flowty-alert-sub {
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888;
}

.flowty-alert .alert-heading {
  font-family: Futura;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.flowty-alert .btn-close {
  color: white;
  background: transparent url("./assets/cross-big.svg") center/1em auto
    no-repeat;
}

.alert-flowty {
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.flowty-img {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.flowty-img:hover {
  color: #888;
  cursor: pointer;
}

.flowty-info {
  font-family: Futura;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

/* .list-flowtys {
  margin-left: 20%;
  margin-right: 20%;
}

.col-flowtys {
  min-height: 150px;
  min-width: 150px;
  margin: 0 10px;
}

@media only screen and (max-width: 1400px) {
  .list-flowtys {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media only screen and (max-width: 1200px) {
  .list-flowtys {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 900px) {
  .list-flowtys {
    margin-left: 0;
    margin-right: 0;
  }
} */

.dropdown-menu {
  background-color: #222;
  border-radius: 20px;
}

.dropdown-menu a {
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.menu-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 30px;
  background-color: #222;
  padding: 13px 40px 15px;

  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.menu-button a {
  padding: 13px 40px 15px;
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.menu-button:hover {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.menu-button:focus,
.menu-button:active {
  background-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none !important;
}

.menu-button:disabled {
  background-color: rgba(34, 34, 34, 0.315) !important;
}

img.Cross-big {
  width: 40px;
  height: 40px;
  padding: 6px;
  object-fit: contain;
  cursor: pointer;
}

img.Cross-big-top {
  width: 40px;
  height: 40px;
  padding: 6px;
  margin-top: 9px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
}

.flowty-input-box {
  width: 270px;
  margin-top: 11px;
  margin-right: 15px !important;
  border-radius: 30px;
  border: none;
  background-color: #222;
}

.flowty-input {
  width: 264px;
  height: 54px;
  padding-left: 32px !important;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  border: none;
  text-align: center;

  font-family: Futura;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.flowty-input:focus {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

body.modal-open > :not(.modal) {
  -webkit-filter: blur(5px) brightness(0.2);
  -moz-filter: blur(5px) brightness(0.2);
  -o-filter: blur(5px) brightness(0.2);
  -ms-filter: blur(5px) brightness(0.2);
  filter: blur(5px) brightness(0.2);
}

.pfp-modal {
  border: none !important;
  outline: none !important;
}

.pfp-modal .modal-content .modal-body {
  padding-right: 90px;
  padding-left: 90px;
}

.pfp-modal-title {
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.modal-title-button {
  margin-left: -40px;
}

.main-action-button {
  padding: 13px 40px 15px;
  border-radius: 30px;
  background-color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.main-action-button:hover {
  background-color: #a89ab0;
}

.flowty-banner-box {
  width: 600px;
  height: 320px;
  margin-top: 10px;
  padding: 0 0 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 10px;
}

.flowty-phone-box {
  width: 360px;
  height: 640px;
  margin-top: 10px;
  padding: 0 0 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 10px;
}

.flowty-banner-message {
  margin-top: 30px;
  font-family: LucianCAT;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.flowty-banner-canvas {
  width: 600px;
  height: 200px;
}

.btn-close {
  color: #fff !important;
}

.special_modal .modal-body {
  background-color: transparent;
}

.special_modal .modal-header {
  background-color: transparent;
  border-bottom: none;
}

.special_modal .modal-content {
  border: none !important;
  outline: none !important;
  background-color: transparent;
}

.message-list {
  position: absolute;
  display: flexbox;
  right: 1%;
  top: 70px;
  z-index: 1100;
}

.connect-button {
  min-width: 120px;
}

.metamask-icon {
  width: 40px;
  height: 40px;
}

.row-border {
  border-bottom: solid 1px #979797;
}

.row {
  --bs-gutter-x: 0 !important;
}

.wallet-connect-hover:hover {
  background-color: rgba(218, 218, 218, 0.2);
  cursor: pointer;
}

.wallet-connect-title {
  font-family: Futura;
  font-size: 20px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.wallet-connect-sub {
  font-family: Futura;
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
}

.twt-pfp {
  z-index: 1000;
  width: 142px;
  height: 142px;
  margin-top: -81px;
}

.twt-title {
  margin-left: 15px;
  font-family: Futura;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.twt-sub {
  margin-left: 15px;
  margin-bottom: 10px !important;
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888;
}

.pfp-banner-canvas {
  border-radius: 10px;
}

.pfp-banner-canvas .konvajs-content {
  border-radius: 10px;
}

.content {
  position: relative;
  padding: 5px;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 50% !important;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content .content-overlay-visible {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 50% !important;
  width: 100%;
  height: 100%;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-red {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content-details-visible {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 1;
}

.content-details-text {
  font-family: Futura;
  font-size: 100px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.content-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
}
.content-progress-bar svg {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

/*
Hide Progress Bar trail
*/
.content-progress-bar .rc-progress-circle-trail {
  display: none;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.download-button {
  width: 175px;
}

.promo-image {
  max-width: 500px;
}

.info-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.pfp-img {
  max-width: 500px;
}

.browse-img {
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.browse-img-disabled {
  opacity: 0.2;
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.browse-img:hover {
  cursor: pointer;
}

.trusted-modal .modal-content .modal-header {
  border-bottom: none !important;
  outline: none !important;
}

.trusted-modal .modal-content .modal-header .btn-close {
  background: transparent url(./assets/cross-big.svg) center/1rem auto no-repeat;
}

.trusted-modal-title {
  font-family: Lucian;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  width: 100%;
  width: 680px;
  border-bottom: solid 1px #979797;
}

.wallet-connect-hover:hover {
  background-color: rgba(218, 218, 218, 0.2);
  cursor: pointer;
}

.wallet-connect-title {
  font-family: Futura;
  font-size: 20px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.wallet-connect-sub {
  font-family: Futura;
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
}

.trusted-modal .modal-content .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.trusted-modal .modal-content .row > * {
  padding-left: 0px;
  padding-right: 0px;
}

.trusted-modal .modal-content {
  background-color: #222 !important;
  border-radius: 10px;
  border: solid 3px rgba(136, 136, 136, 0.5);
}

.trusted-modal-content .modal-content {
  width: 900px !important;
  min-width: 900px !important;
}

.trusted-modal-content .modal-body {
  padding: 0px 110px 0px 110px !important;
}

.modal-button-margin {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.staking-panel {
  background: transparent url("./assets/staking-panel.webp") center
    center/contain no-repeat;
  height: 470px;
  width: 880px;
  padding-right: 30px;
}

.staking-img {
  width: 110px;
  height: 110px;
  margin-right: 40px;
}

.staking-img-click {
  cursor: pointer;
}

.staking-lbl {
  font-family: Futura;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.staking-sub-lbl {
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.ml-10-i {
  margin-left: 10px !important;
}

.staking-lbl-cnt {
  font-family: Futura;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.staking-info-row {
  margin-left: 98px;
}

.mt-40 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 80px;
}

.staking-div {
  max-width: 880px;
}

.staking-tab {
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 25px;
}

.nav-tabs {
  border-bottom: none;
  align-items: center !important;
  justify-content: center !important;
}

.nav-tabs .nav-link {
  margin-bottom: 0px;
  border-radius: 30px;
  background-color: #222;
  padding: 9px 40px 9px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  color: white !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 13px 40px 15px;
  border: none;
  border-radius: 0px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-bottom: 0px !important;

  padding: 13px 40px 15px;

  color: #888 !important;
}

.stake-img-center {
  width: 50%;
  height: 51%;
  display: block;
  margin: 0 auto;
}

.staked-ind {
  background: white;
  position: absolute;
  margin-left: 10px;
  margin-right: 10px;
  left: 0%;
  right: 0%;
  top: 0%;
  text-align: center;
  border-radius: 50% !important;
  width: 23%;
  height: 23%;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.staked-img-center {
  width: 90%;
  height: 91%;
  display: block;
  margin: 0 auto;
}

.flowty-img-progress {
  margin-left: -7px;
  margin-right: -20px;
  margin-top: -20px;
  width: 50px;
  height: 50px;
}

.flowtys-progress-txt {
  margin-left: 20px;
  margin-right: 70px;
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.morphys-logo {
  width: 179px;
  height: 60px;
  box-shadow: 2px 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.new-icon {
  width: 120px;
  height: 95.7px;
}

.new-icon-v {
  visibility: hidden;
}

.flowtys-art {
  max-width: 512px;
  box-shadow: 2px 10px 10px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-height: 900px) {
  .flowtys-art {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
}

.flowtys-art-box {
  align-items: center !important;
  justify-content: center !important;
  margin-left: 50px !important;
}

@media only screen and (max-height: 900px) {
  .flowtys-art-box {
    margin-left: 5px;
  }
}

.flowtys-art-title {
  font-family: Futura;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.flowtys-art-text {
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.flowtys-art-price {
  font-family: Futura;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.flowtys-art-price-sub {
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.mint-button {
  min-width: 192px;
  height: 54px;
  border-radius: 30px;
  background-color: black;
  border: none;

  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}
.sale-mint-button:hover {
  background-color: #e5c100; /* Green */
}
.sale-max {
  width: 77px !important;
  height: 34px !important;
  margin-top: 5px !important;
  margin-left: 15px !important;
  border-radius: 30px !important;
  border: none;
  background-color: #d44040;

  font-family: Futura;
  font-size: 16px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.sale-add {
  width: 44px !important;
  height: 44px !important;
  margin-top: 5px !important;
  /* margin-left: 5px !important; */
  padding: 0px 0px 0px 0px !important;
  border-radius: 30px !important;
  border: none;
  background-color: rgba(227, 208, 178, 0.2);
  /* background-color: #e5e5e5; */

  /* font-family: Futura; */
  font-size: 25px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.sale-max-reached {
  font-family: Futura;
  font-size: 12px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.sale-max:hover {
  background-color: #6b6b6b; /* Green */
}

.sale-add:hover,
.sale-add:focus,
.sale-add:active {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  outline: none;
}

.sale-input:focus,
.sale-input:focus {
  background-color: transparent;
  outline: none;
}

.sale-form {
  margin-top: -80px !important;
  padding-left: 25px !important;
}

.sale-input {
  width: 90px !important;
  height: 54px;
  padding-left: 12px !important;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  border: none;
  text-align: center;

  font-family: Futura;
  font-size: 24px !important;
  font-weight: bold;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}
.sale-input:disabled {
  background-color: transparent;
}
.sale-input:focus {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.sale-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #888;
  opacity: 1; /* Firefox */
}

.sale-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #888;
}

.sale-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #888;
}

.sale-input-box {
  width: 484px;
  height: 90px;
  border-radius: 48px;
  border: none;
  background-color: #222 !important;
}

.sale-mint-button {
  width: 144px;
  height: 54px;
  margin: 0 0 0 20.5px;
  padding: 13px 44.5px 15px;
  border-radius: 30px !important;
  background-color: #fff;
  margin-right: 20px;
  margin-left: 20px !important;
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.sale-add-margin {
  margin-left: 20px !important;
}
